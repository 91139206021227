<!-- 头部  板块-->
<template>
  <div class="nav" v-if="shownav">
    <div class="wAuto">
      <div class="logo" @click="goAboutUs()">
        <img src="@/assets/logo.svg" alt="" />
      </div>
      <div class="nav_tab">
        <el-menu
          :default-active="activeIndex2"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          text-color="#82268A"
          active-text-color="#82268A"
        >
          <el-submenu index="1">
            <template slot="title"
              ><span @click="goPage('aboutUs')">关于我们</span></template
            >

            <el-menu-item index="/brandDevelop">品牌之路</el-menu-item>
            <el-menu-item index="mission">使命与愿景</el-menu-item>
            <!-- <el-menu-item index="mission2">使命与愿景2</el-menu-item> -->

            <el-menu-item index="/history">历史与里程碑</el-menu-item>
            <el-menu-item index="/bility">社会责任</el-menu-item>
            <el-menu-item index="/trends">企业动态</el-menu-item>
          </el-submenu>
          <!-- 应用场景 scene-->
          <el-submenu index="2">
            <template slot="title"
              ><span @click="goPage('scene')">应用场景</span></template
            >
            <el-menu-item index="/ultrasonic">超声</el-menu-item>
            <el-menu-item index="/molyb">钼靶</el-menu-item>
            <el-menu-item index="/resonance">核磁共振</el-menu-item>
            <el-menu-item index="/detection">伽玛射线探测</el-menu-item>
            <!-- <el-menu-item index="2-5">术中成像</el-menu-item> -->
          </el-submenu>
          <!-- 产品与解决方案 -->

          <el-submenu index="3">
            <template slot="title"
              ><span @click="goPage('product')">产品与解决方案</span></template
            >
            <el-menu-item index="productdetail?id=7"
              >D-iCare医生工作室</el-menu-item
            >
            <el-menu-item index="flexML">MAMMOTOME FLEX® 睦兰®</el-menu-item>
            <el-menu-item index="productdetail?id=0"
              >利旋™创升中国整体解决方案</el-menu-item
            >
            <el-menu-item index="goodsPage?id=1"
              >Mammotome revolve™</el-menu-item
            >
            <el-menu-item index="goodsPage?id=2">Mammotome®</el-menu-item>
            <el-menu-item index="productdetail?id=3"
              >Mammotome elite</el-menu-item
            >
            <el-menu-item index="productdetail?id=5">Neoprobe</el-menu-item>
            <!-- <el-menu-item index="productdetail?id=4"
              >Mammotome Confirm</el-menu-item
            > -->
          </el-submenu>
          <!-- 专业聚焦 focus-->
          <el-submenu index="4">
            <template slot="title">
              <span @click="goPage('focus')">专业聚焦</span></template
            >
            <el-menu-item index="college">微创学苑</el-menu-item>
            <el-menu-item @click="phoneGOPage('zhibo')">直播中心</el-menu-item>
            <el-menu-item index="healthCare">健康关爱</el-menu-item>
          </el-submenu>
          <!-- 联系我们 -->
          <el-menu-item index="contactUs">联系我们</el-menu-item>
          <el-submenu index="6">
            <template slot="title"
              ><span @click="goPage('recruit')">招贤纳士</span></template
            >
            <el-menu-item index="/recruitJob">热招职位</el-menu-item>
            <el-menu-item index="/pardon?id=1">全面薪酬</el-menu-item>
            <el-menu-item index="pardon?id=2">多元包容</el-menu-item>
            <!-- <el-menu-item index="pardon?id=3">员工故事</el-menu-item> -->
          </el-submenu>
          <el-submenu
            index="7"
            style="margin: 0 0rem 0 19rem; vertical-align: middle"
          >
            <template slot="title"
              ><span class="flagg" style="margin-top: -0.19rem"
                ><img src="@/assets/nav/CH.png" alt="" /></span
              ><span
                style="
                  color: rgb(102, 31, 108);
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                "
                >简体中文</span
              ></template
            >
            <el-menu-item index=""
              ><span style="margin-right: 1rem" class="flagg"
                ><img src="@/assets/nav/CH.png" alt="" /></span
              ><span>简体中文</span></el-menu-item
            >
            <el-menu-item index="/USA"
              ><span style="margin-right: 1rem" class="flagg"
                ><img src="@/assets/nav/US.png" alt="" /></span
              ><span>English</span></el-menu-item
            >
          </el-submenu>
        </el-menu>
      </div>
    </div>
    <div class="phone_nav">
      <div class="phone_con">
        <div class="logo" @click="goAboutUs()">
          <img src="@/assets/logo.svg" alt="" />
        </div>
        <div class="nav_tab" @click="showMenu()">
          <img src="@/assets/nav/menu.png" alt="" />
        </div>
      </div>
      <van-popup
        v-model="show"
        position="right"
        :style="{ height: '100%', width: '62%' }"
        class="popup"
      >
        <div class="close" @click="onClose">
          <img src="@/assets/nav/close.png" alt="" />
        </div>
        <div ref="tab1" class="tab1" style="height: 100%">
          <ul>
            <li v-for="(item, index) in PhoneNav" :key="index">
              <van-cell is-link @click="showTab2(item, index)">{{
                item.title
              }}</van-cell>
            </li>
          </ul>
          <ul>
            <li
              v-for="(item, index) in tab2data"
              :key="index"
              @click="goPage2(item)"
            >
              <van-cell>{{ item.title }}</van-cell>
            </li>
          </ul>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeIndex2: "1",
      show: false,
      shownav: true,
      changeTab: true,
      PhoneNav: [
        {
          id: 1,
          path: "",
          title: "关于我们",
          children: [
            {
              id: 10,
              path: "/aboutUs",
              title: "关于我们",
            },
            {
              id: 11,
              path: "/brandDevelop",
              title: "品牌之路",
            },
            {
              id: 12,
              path: "/mission",
              title: "使命与愿景",
            },

            {
              id: 13,
              path: "/history",
              title: "历史与里程碑",
            },
            {
              id: 14,
              path: "/bility",
              title: "社会责任",
            },
            {
              id: 15,
              path: "/trends",
              title: "企业动态",
            },
          ],
        },
        {
          id: 2,
          path: "",
          title: "应用场景",
          children: [
            {
              id: 20,
              path: "/scene",
              title: "应用场景",
            },
            {
              id: 21,
              path: "/ultrasonic",
              title: "超声",
            },
            {
              id: 22,
              path: "/molyb",
              title: "钼靶",
            },

            {
              id: 23,
              path: "/resonance",
              title: "核磁共振",
            },
            {
              id: 24,
              path: "/detection",
              title: "伽玛射线探测",
            },
          ],
        },
        {
          id: 3,
          path: "",
          title: "产品与解决方案",
          children: [
            {
              id: 30,
              path: "/product",
              title: "产品与解决方案",
            },
            {
              id: 31,
              path: "/productdetail?id=7",
              title: "D-iCare医生工作室",
            },
            {
              id: 32,
              path: "/productdetail?id=0",
              title: "利旋™创升中国整体解决方案",
            },

            {
              id: 33,
              path: "/goodsPage?id=1",
              title: "Mammotome revolve™",
            },
            {
              id: 34,
              path: "/goodsPage?id=2",
              title: "Mammotome®",
            },
            {
              id: 35,
              path: "/productdetail?id=3",
              title: "Mammotome elite",
            },
            {
              id: 36,
              path: "/productdetail?id=5",
              title: "Neoprobe",
            },
            {
              id: 37,
              path: "/productdetail?id=4",
              title: "Mammotome Confirm",
            },
          ],
        },
        {
          id: 4,
          path: "",
          title: "专业聚焦",
          children: [
            {
              id: 40,
              path: "/focus",
              title: "专业聚焦",
            },
            {
              id: 41,
              path: "/college",
              title: "微创学苑",
            },
            {
              id: 42,
              path: "zhibo",
              title: "直播中心",
            },

            {
              id: 43,
              path: "/healthCare",
              title: "健康关爱",
            },
          ],
        },
        {
          id: 5,
          path: "/contactUs",
          title: "联系我们",
        },
        {
          id: 6,
          path: "",
          title: "招贤纳士",
          children: [
            {
              id: 60,
              path: "/recruit",
              title: "招贤纳士",
            },
            {
              id: 61,
              path: "/recruitJob",
              title: "热招职位",
            },
            {
              id: 62,
              path: "/pardon?id=1",
              title: "全面薪酬",
            },

            {
              id: 63,
              path: "/pardon?id=2",
              title: "多远包容",
            },
          ],
        },
      ],
      tab2data: [],
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(newval) {
        // console.log(newval);
        if (newval.path == "/contactUs" || newval.path == "/home") {
          this.isActive = false;
        } else {
          this.isActive = true;
        }
        if (
          newval.path == "/lixuan-lp" ||
          newval.path == "/ruiyan-lp" ||
          newval.path == "/ruixuan-lp"
        ) {
          console.log(1111);
          this.shownav = false;
          // this.isActive = false;
        } else {
          this.shownav = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSelect(keyPath) {
      console.log(keyPath);
      if (keyPath == "/zhibo") {
        window.open(
          "https://wx.vzan.com/live/pc/index?liveId=2100458963&shareuid=0"
        );
      }
      if (keyPath == "/USA") {
        window.open("https://www.mammotome.com/uk/en/");
        // https://www.mammotome.com/uk/en/
      }
      if (keyPath != "/USA" && keyPath != "/zhibo") {
        this.$router.push({
          path: keyPath,
        });
      }
    },
    goAboutUs() {
      this.$router.push({
        path: "/home",
      });
    },
    goPage(item) {
      this.$router.push({
        path: "/" + item,
      });
      // console.log(item);
    },

    // ////////////////////////////////////////////////移动端
    // 显示移动端菜单
    showMenu() {
      this.show = true;
      this.$refs.tab1.style.transform = "translateX(0%)";
    },
    showTab2(item, index) {
      item, index;
      if (index == 4) {
        this.$refs.tab1.style.transform = "translateX(0%)";
        this.$router.push(item.path);
        this.show = false;
      } else {
        this.changeTab = false;
        this.tab2data = item.children;
        this.$refs.tab1.style.transform = "translateX(-50%) ";
      }
    },
    // 导航栏点击关闭
    onClose() {
      console.log("111");
      if (this.changeTab) {
        this.show = false;
      } else {
        this.$refs.tab1.style.transform = "translateX(0%) ";
        this.show = true;
        this.changeTab = true;
      }
    },
    goPage2(item) {
      console.log("item");
      if (item.path == "zhibo") {
        window.open(
          "https://wx.vzan.com/live/pc/index?liveId=2100458963&shareuid=0"
        );
      } else {
        this.$router.push({
          path: item.path,
        });
        this.show = false;
      }

      // console.log(item);
    },
    phoneGOPage(item) {
      // console.log(item);
      if (item == "zhibo") {
        window.open(
          "https://wx.vzan.com/live/pc/index?liveId=2100458963&shareuid=0"
        );
      } else if (item == "caiye") {
        this.showagreement = true;
        // console.log(1);
      } else {
        this.$router.push({
          path: item,
        });
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less">
@media screen and (min-width: 768px) {
  .nav {
    width: 100%;
    height: 8.8rem;
    background-color: #fff;
    position: fixed;
    z-index: 1000;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    .phone_nav {
      display: none;
    }
    .wAuto {
      height: 88px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo {
        width: 17rem;
        height: 2rem;
        // height: 1.042vw;
        //   height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .nav_tab {
        // width: 54.167vw;
        height: 100%;
        margin-left: 14rem;

        /deep/.el-input__prefix {
          display: none;
        }
        .el-menu-item {
          // font-weight: bold;
          font-size: 1.8rem;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        }
        .el-submenu__title * {
          // font-weight: bold;
          font-size: 1.7rem;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        }
        .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
          display: none;
        }
        .el-menu--horizontal > .el-submenu .el-submenu__title {
          line-height: 8.8rem !important;
          height: 8.8rem !important;
          // font-size: 1.8rem;
          // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        }
        .el-menu--horizontal .el-menu .el-menu-item,
        .el-menu--horizontal .el-menu .el-submenu__title {
          font-size: 1.7rem;
        }
        .el-menu {
          height: 8.8rem;
          display: flex;
          justify-content: flex-end;
          border-bottom: 0 !important;
        }
        .el-menu--horizontal > .el-submenu {
          margin-left: 3.6rem;
        }
        .el-menu-item {
          height: 8.8rem;
          font-size: 1.7rem;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          line-height: 8.8rem !important;
          border-bottom: 0 !important;
          margin-left: 3.6rem;
          padding: 0;
          .flagg {
            margin-right: 0.4rem;
            display: inline-block;

            width: 2.7rem;
            height: 1.8rem;
            img {
              width: 100%;
            }
          }
        }

        .el-submenu__title {
          border-bottom: 0 !important;
          display: flex;
          // align-items: center;
          padding: 0;
        }

        .flagg {
          margin-right: 0.4rem;
          display: inline-block;

          width: 2.7rem;
          height: 1.8rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .el-menu--horizontal .el-menu .el-menu-item {
    font-size: 1.5rem;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    display: flex;
    align-content: center;
  }
}
@media screen and (max-width: 768px) {
  .nav {
    width: 100%;
    height: 10.667vw;
    background-color: #fff;
    padding: 0 5.867vw;
    box-sizing: border-box;
    position: fixed;
    z-index: 1000;
    box-shadow: 0vw 0vw 2.133vw 0vw rgba(0, 0, 0, 0.15);
    .wAuto {
      display: none;
    }
    .phone_nav {
      width: 100%;
      position: relative;
      z-index: 999;
      .van-popup__close-icon {
        font-size: 7.15vw;
      }
      .van-popup__close-icon--top-right {
        top: 2.83vw;
        right: 3.83vw;
      }

      .phone_con {
        width: 100% !important;
        height: 10.667vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
          width: 26.4vw;
          height: 8.2vw;
          // height: 1.042vw;
          //   height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .nav_tab {
          width: 5.6vw;
          height: 5.2vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .popup {
        overflow: hidden;
        // position: static;
        z-index: -1;
        .close {
          width: 5.333vw;
          height: 5.333vw;
          position: absolute;
          z-index: 3000;
          top: 5vw;
          right: 5vw;
          img {
            width: 100%;
          }
        }
        .tab1 {
          transition: 0.3s;
          width: 200%;
          padding: 20vw 5.333vw 0 5.333vw;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          ul:nth-child(1) {
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            width: 100%;
            height: 100%;
            .van-cell {
              line-height: 7.25vw;
              font-size: 4vw;
              // margin-bottom: 5vw;
            }
            .van-cell__right-icon {
              margin-left: -2vw;
              font-size: 4vw;
              line-height: 7.25vw;
            }
            .van-icon-arrow:before {
              margin-left: -7vw;
            }
            li:nth-child(5) {
              .van-cell {
                i {
                  display: none;
                }
              }
            }
          }
          ul:nth-child(2) {
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            width: 100%;
            height: 100%;
            padding: 0 0 0 5.333vw;
            box-sizing: border-box;
            .van-cell {
              line-height: 7.25vw;
              font-size: 4vw;
              margin-bottom: 2vw;
            }

            li:nth-child(1) {
              .van-cell {
                line-height: 7.25vw;
                font-size: 4vw;
                margin-bottom: 2vw;
              }
            }
          }
        }
      }
    }
  }
}
</style>
